
<template>
  <div class="page-farm-crops">
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header
            :total-count="paging.totalCount"
        ></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="rounded-sm" size="sm" @click="showModal">{{i18n.register}}</b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::crops-table-->
        <div class="crops-table">

          <v-data-table
              :headers="fields"
              :items="cropItems"
              class="elevation-1 farm-data-table"
              no-data-text="No Data"
              hide-default-footer
          >
            <template v-slot:[`item.fieldH`]="{ item }">
              <b-button size="sm" variant="outline-dark" @click="showFarmCropModal(item)">
                  <span class='icon-xs fas fa-pen mr-2'></span>
                  <span class="font-weight-bold">{{i18n.edit}}</span>
                </b-button>
              <b-button size="sm" variant="dark" class="rounded-sm" @click="delFarmCrop(item['fieldH'], item['fieldA'])">
                <span class="fas fa-trash font-size-sm mr-2"></span>
                <span class="text font-weight-normal">{{i18n.remove}}</span>
              </b-button>
            </template>
          </v-data-table>
          <!--begin::pagination-->
            <div v-if="totalPage" class="pagination mt-12">
              <b-pagination-nav
                :number-of-pages="totalPage"
                :link-gen="linkGen"
                use-router
                limit="10"
              >
                <template #first-text><i class="fas fa-angle-double-left"></i></template>
                <template #prev-text><i class="fas fa-angle-left"></i></template>
                <template #next-text><i class="fas fa-angle-right"></i></template>
                <template #last-text><i class="fas fa-angle-double-right"></i></template>
                <template #page="{ page, active }">
                  <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
                  <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
                </template>
              </b-pagination-nav>
            </div>
            <!--end::pagination-->
        </div>
        <!--end::crops-table-->
      </div>
      <!--end::Body-->
    </div>

    <crop-modal
      :target="target"
      :callback="getCrops"
    ></crop-modal>

    <crop-env-modal
      :farmCropModal='farmCropModal'
      :selectedCrop='selectedCrop'
      :getCrops='getCrops'
    ></crop-env-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_DELETE_MY_CROP,ACT_GET_MY_CROPS} from '@/core/services/variable';
import {getItems, getResult, getValueWithDefault, isSuccess, lan} from '@/core/services/funcs';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';
import CropModal from '@/components/modal/CropModal';
import CropEnvModal from '@/components/modal/CropEnvModal';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';

export default {
  name: "FarmCrops",
  components: {
    CropModal,
    TFarmonTableHeader,
    CropEnvModal
  },
  created() {
    if(this.$route.query.page) this.paging.pageNo = this.$route.query.page;
    this.getCrops()
  },
  watch: {
    '$route' (to) {
      if(to.query.page){
        this.paging.pageNo = to.query.page;
      }else {
        this.paging.pageNo = 1
      }
      this.getCrops();
    },
  },

  data() {
    return {
       i18n: {
        numbering: i18n.t('farm.cultivationCropMangement.numbering'),
        cropName: i18n.t('farm.cultivationCropMangement.cropName'),
        cultivationCropType: i18n.t('farm.cultivationCropMangement.cultivationCropType'),
        cultivationEnvStandard: i18n.t('farm.cultivationCropMangement.cultivationEnvStandard'),
        growthPeriod: i18n.t('farm.cultivationCropMangement.growthPeriod'),
        standardWeight: i18n.t('farm.cultivationCropMangement.standardWeight'),
        monthAvgOutput: i18n.t('farm.cultivationCropMangement.monthAvgOutput'),
        controllerConnection: i18n.t('farm.cultivationCropMangement.controllerConnection'),
        edit: i18n.t('farm.cultivationCropMangement.edit'),
        remove: i18n.t('farm.cultivationCropMangement.remove'), 
        register: i18n.t('farm.cultivationCropMangement.register'),
        error: i18n.t('farm.cultivationCropMangement.error'),
        cropD1: i18n.t('farm.cultivationCropMangement.cropD1'),
        cropD2: i18n.t('farm.cultivationCropMangement.cropD2'),
        cropD3: i18n.t('farm.cultivationCropMangement.cropD3'),

       },
      fields: [
        { value: 'idx', text: i18n.t('farm.cultivationCropMangement.numbering'), width: '80px' },
        { value: 'fieldA', text: i18n.t('farm.cultivationCropMangement.cropName')},
        { value: 'fieldB', text: i18n.t('farm.cultivationCropMangement.cultivationCropType')},
        { value: 'fieldC', text: i18n.t('farm.cultivationCropMangement.cultivationEnvStandard')},
        { value: 'fieldD', text: i18n.t('farm.cultivationCropMangement.growthPeriod')},
        { value: 'fieldE', text: i18n.t('farm.cultivationCropMangement.standardWeight')},
        { value: 'fieldF', text: i18n.t('farm.cultivationCropMangement.monthAvgOutput'), width: '15%' },
        { value: 'fieldG', text: i18n.t('farm.cultivationCrop.controllerConnection')},
        { value: 'fieldH', text: '', width: '80px' }
      ],
      paging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        hasMore: false
      },

      crops: [],
      farmCropModal: 0,
      selectedCrop: {},

      target: 0,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.farm.info'), icon: 'fas fa-clipboard' }, { title: i18n.t('topNavBar.farm.cropManagement')}]);
    window.scrollTo(0,0);
  },
  computed: {
    totalPage() {
      if(this.paging){
        return Math.ceil(this.paging.totalCount / this.paging.pageSize);
      }
      return 0;
    },
    cropItems() {
      const items = [];
      this.crops.forEach((crop, idx) => {
        items.push({
          'idx': idx + 1,
          'editMode': false,
          'fieldA' : lan() == 'ko'? crop.cropNm : crop.cropNmEn,
          'fieldB' : getValueWithDefault(lan() == 'ko' ? crop.cropType : crop.cropTypeEn),
          'fieldC' : getValueWithDefault(crop.baseNm),
          'fieldD' : getValueWithDefault(crop.growPeriod),
          'fieldE' : getValueWithDefault(crop.standardWeight),
          'fieldF' : (crop.monthAvgOutput || 0),
          'fieldG' : getValueWithDefault(crop.cropConnectNm),
          'fieldH' : crop.cultivateCropSeq,
        })
      });
      return items;
    }
  },
  methods: {
    showFarmCropModal(item){
      this.farmCropModal++
      this.crops.forEach(crop =>{
        if(crop.cultivateCropSeq == item.fieldH){
          this.selectedCrop = crop
        }
      })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getCrops(){
      this.$store.dispatch(ACT_GET_MY_CROPS, {pageNo: this.paging.pageNo, pageSize: this.paging.pageSize}).then(resp => {
        this.crops = getItems(resp)
        for(let i in this.crops){
          const cropEnvJson = JSON.parse(this.crops[i].cropEnvJson)
          for(let j in Object.keys(cropEnvJson)){
            this.crops[i][Object.keys(cropEnvJson)[j]] = cropEnvJson[Object.keys(cropEnvJson)[j]]
          }
        }
      this.paging.totalCount = resp.paging.totalCount
      })
    },
    delFarmCrop(cultivateCropSeq, cropNm){
      const yesCallback = () => {
        this.$store.dispatch(ACT_DELETE_MY_CROP, cultivateCropSeq).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.cropD1 + cropNm + this.i18n.cropD2, color: 'success'});
            this.getCrops();
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
          }
        }).catch(e => {
          console.error(e);
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.cropD1 + cropNm + this.i18n.cropD3},
        callback: yesCallback
      })
    },
    showModal(){
      this.target++;
    },
  }
};
</script>

<template>
  <b-modal
      v-model="cropModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
  >
    <div class="popup-crops-form">
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 align-items-center">
          <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            {{i18n.registerCrop}}
          </span>
          </h3>
          <div class="card-toolbar toolbar-symbol">
            <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="cropModal = false">
              <i class="la la-close font-size-h5"></i>
            </b-button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
          <!--begin::crops-type-->
          <div class="crops-type">
            <b-form-group label-cols="12" label-cols-lg="6" :label="i18n.registerCrop" label-for="input-default">
              <b-form-select v-model="cropTypeDcd" :options="cropTypeOptions" @change="movePage(1)"></b-form-select>
            </b-form-group>
          </div>
          <!--end::crops-type-->
          <!--begin::crops-item-->
          <div class="crops-item">
            <dl class="text-group row mb-7">
              <dt class="title col-12 col-lg-6">
                <span class="title font-weight-bold font-size-lg">{{i18n.selectCultivationCrop}}</span>
              </dt>
              <dd class="description col-12 col-lg-6">
                <span class="text font-weight-bold text-primary">{{ selectedCrop }}</span>
              </dd>
            </dl>
          </div>
          <!--end::crops-item-->
          <!--begin::crops-table-->
          <div class="crops-table">
            <b-table
                responsive
                :items="cropItems"
                :fields="fields"
                head-variant="light"
                class="table-vertical-center border-bottom"
                :empty-text="i18n.noCropResults"
                show-empty
            >
              <template #cell(fieldA)="data">
                <b-form-checkbox
                    name="radio-size"
                    v-model="selected"
                    :value="{'cropNo': data.item.cropNo,
                    'monthAvgOutput' : data.item.monthAvgOutput,
                    'cropEnvJson' : data.item.cropEnvJson,
                    'nutrientSolutionNo' : data.item.nutrientSolutionNo,
                    'cropNm': data.item.fieldA}"
                >{{ data.item.fieldA }}</b-form-checkbox>
              </template>
              <template #cell(fieldF)="data">{{ data.item.fieldF }}
                <div v-b-tooltip.hover.v-dark.right :title="data.item.fieldF" class="d-inline-block">
                  <b-icon icon="info-square-fill" aria-hidden="true" class="text-dark"></b-icon>
                </div>
              </template>
            </b-table>

            <modal-pagination
                :page-no="pageNo"
                :page-size="pageSize"
                :total-count="totalCount"
                :callback="movePage"
                :effect-value="effectValue"
            ></modal-pagination>
          </div>
          <!--end::crops-table-->
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="card-footer">
          <b-button variant="primary" size="lg" v-bind:class="{'spinner spinner-light spinner-right': loaded}" @click="insertFarmCrops"><span >{{i18n.register}}</span></b-button>
          <b-button variant="secondary" size="lg" class="ml-3" @click="cropModal = false"><span class="">{{i18n.close}}</span></b-button>
        </div>
        <!--end::Footer-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import ModalPagination from '@/components/ModalPagination';
import {
  getItems,
  getOptions,
  getPaging,
  getResult,
  getValueWithDefault,
  getValueWithUnit,
  isSuccess,
  lengthCheck,
  lan
} from '@/core/services/funcs';
import {ACT_GET_COMMON_CODES, ACT_GET_CROPS, ACT_INSERT_MY_CROPS} from '@/core/services/variable';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "CropModal",
  components: {
    ModalPagination
  },
  props: {
    target: Number,
    callback: Function
  },
  created() {
    this.$store.dispatch(ACT_GET_COMMON_CODES, {dcd: 1007}).then(resp => {
      if(lengthCheck(resp)) {
        this.cropTypeOptions = getOptions(getItems(resp), this.i18n.all, true);
      }
    });
  },
  watch: {
    target(){
      this.cropModal = true;
      this.loaded = false;
      this.getCrops();
    }
  },
  computed: {
    selectedCrop() {
      if(this.selected){
        return this.selected.map(x => x.cropNm).join(', ');
      }
      return '';
    },
    cropItems() {
      const items = [];
      this.crops.forEach(crop => {
        items.push(
          {
            'fieldA' : lan() =='ko'? crop.cropNm : crop.cropNmEn, 'fieldB' : getValueWithDefault(crop.cropCd), 'fieldC' : getValueWithDefault(lan()=='ko'? crop.cropType : crop.cropTypeEn),
            'fieldD' : getValueWithUnit(crop.growPeriod, '일'), 'fieldE' : getValueWithUnit(crop.standardWeight, 'g'),
            'fieldF' : getValueWithDefault(crop.baseNm),
            'cropNo' : crop.cropNo,
            'monthAvgOutput': crop.monthAvgOutput,
            'cropEnvJson' : crop.cultivateEnvJson,
            'nutrientSolutionNo': crop.nutrientSolutionNo
          }
        )
      });
      return items;
    }
  },
  data() {
    return {
      i18n: {
      registerCrop: i18n.t('farm.cultivationCropMangement.registerCrop'),
      selectCultivationCrop: i18n.t('farm.cultivationCropMangement.selectCultivationCrop'),
      selectCultivationCropType: i18n.t('farm.cultivationCropMangement.selectCultivationCropType'),
      register: i18n.t('farm.cultivationCropMangement.register'),
      close: i18n.t('farm.cultivationCropMangement.close'),
      noCropResults: i18n.t('farm.cultivationCropMangement.noCropResults'),
      all: i18n.t('farm.cultivationCropMangement.all')
      },
      cropModal: false,
      loaded: false,

      selected: [],

      cropTypeDcd: '',
      cropTypeOptions: [],

      fields: [
        { key: 'fieldA', label: `${i18n.t('farm.cultivationCropMangement.cropName')}` },
        { key: 'fieldB', label: `${i18n.t('farm.cultivationCropMangement.cropNumber')}` },
        { key: 'fieldC', label: `${i18n.t('farm.cultivationCropMangement.cultivationCropType')}` },
        { key: 'fieldD', label: `${i18n.t('farm.cultivationCropMangement.growthPeriod')}` },
        { key: 'fieldE', label: `${i18n.t('farm.cultivationCropMangement.standardWeight')}` },
        { key: 'fieldF', label: `${i18n.t('farm.cultivationCropMangement.cultivationEnvStandard')}` }
      ],
      crops: [],
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      effectValue: 0
    };
  },
  methods: {
    movePage(i){
      this.getCrops(i);
    },
    getCrops(i){
      this.pageNo = (i ? i : 1);
      this.$store.dispatch(ACT_GET_CROPS, {pageNo: this.pageNo, pageSize: this.pageSize, cropTypeDcd: this.cropTypeDcd}).then(resp => {
        if(lengthCheck(resp)){
          this.crops = getItems(resp);
          this.crops.forEach((crop,idx)=>{
            let envJson = {}
            if (crop.cultivateEnvJson != null){
              envJson = JSON.parse(crop.cultivateEnvJson)
            }else{
              envJson = {
                seedMinDay: 0,
                seedMedianDay: 0,
                seedMaxDay: 0,
                seedEcMin:0,
                seedEcMedian:0,
                seedEcMax:0,
                seedPhMin:0,
                seedPhMedian:0,
                seedPhMax:0,

                transplantMinDay: 0,
                transplantMedianDay: 0,
                transplantMaxDay: 0,
                transplantEcMin:0,
                transplantEcMedian:0,
                transplantEcMax:0,
                transplantPhMin:0,
                transplantPhMedian:0,
                transplantPhMax:0,

                lastfeedlantMinDay: 0,
                lastfeedMinDay: 0,
                lastfeedMedianDay: 0,
                lastfeedMaxDay: 0,
                lastfeedDayTemperatureMin:0,
                lastfeedDayTemperatureMedian:0,
                lastfeedDayTemperatureMax:0,
                lastfeedNightTemperatureMin:0,
                lastfeedNightTemperatureMedian:0,
                lastfeedNightTemperatureMax:0,

                lastfeedDayWaterTemperatureMin:0,
                lastfeedDayWaterTemperatureMedian:0,
                lastfeedDayWaterTemperatureMax:0,
                lastfeedNightWaterTemperatureMin:0,
                lastfeedNightWaterTemperatureMedian:0,
                lastfeedNightWaterTemperatureMax:0,

                lastfeedDayHumidityMin:0,
                lastfeedDayHumidityMedian:0,
                lastfeedDayHumidityMax:0,
                lastfeedNightHumidityMin:0,
                lastfeedNightHumidityMedian:0,
                lastfeedNightHumidityMax:0,

                lastfeedLight:0,
                lastfeedLightR:0,
                lastfeedLightG:0,
                lastfeedLightB:0,
                lastfeedLightW:0,
                lastfeedLightIntensityMin:0,
                lastfeedLightIntensityMax:0,

                lastfeedEcMin:0,
                lastfeedEcMedian:0,
                lastfeedEcMax:0,
                lastfeedPhMin:0,
                lastfeedPhMedian:0,
                lastfeedPhMax:0,

                lastfeedCo2Min:0,
                lastfeedCo2Median:0,
                lastfeedCo2Max:0,
              }
            }
            envJson.growPeriod = crop.growPeriod
            envJson.standardWeight = crop.standardWeight
            this.crops[idx].cultivateEnvJson = JSON.stringify(envJson)
          })
          const paging = getPaging(resp);
          this.pageNo = paging.pageNo;
          this.totalCount = paging.totalCount;
        }else{
          this.crops = []
          this.pageNo = 1;
          this.totalCount = 0;
        }
        this.effectValue++;
      }).catch(e => {
        console.error(e);
        this.crops = []
        this.pageNo = 1;
        this.totalCount = 0;
      });
    },
    insertFarmCrops(){
      if(this.loaded) return;

      if(this.selected && this.selected.length){
        this.loaded = true
        const cropList = [];

        this.selected.forEach(sel => {
          cropList.push({'cropNo': sel.cropNo, 'monthAvgOutput': sel.monthAvgOutput, 'cropEnvJson': sel.cropEnvJson, 'nutrientSolutionNo': sel.nutrientSolutionNo});
        })

        const yesCallback = () => {
          this.$store.dispatch(ACT_INSERT_MY_CROPS, {
            cropsJson: JSON.stringify(cropList)
          }).then(resp => {
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.cultivationCropMangement.cropAddSuccess'), color: 'success'});
              this.cropModal = false;
              this.callback();
            }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            }
            this.loaded = false;
          }).catch(e => {
            console.error(e);
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.cultivationCropMangement.cropAddError')});
            this.loaded = false;
          });
        }

        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
          info: {title: i18n.t('farm.cultivationCropMangement.addCropToFarm')},
          callback: yesCallback,
          cancelCallback: () => {this.loaded = false}
        });
      }else {
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.cultivationCropMangement.checkCrop')});
      }
    }
  }
};
</script>
<template>
  <b-modal v-model="cropModal"
                size="xxl"
                hide-footer
                hide-header
                no-close-on-backdrop
                no-close-on-esc
                hide-header-close
        >
        <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 align-items-center">
          <h3 class="card-title align-items-start flex-column float-left">
          <span class="title font-weight-bold">
            {{cropNm}} {{i18n.edit}}
          </span>
          </h3>
          <div class="card-toolbar toolbar-symbol float-right">
            <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="cropModal = false">
              <i class="la la-close font-size-h5"></i>
            </b-button>
          </div>
        </div>
        <!--end::Header-->
        <!-- begin::card-body -->
        <div class='card-body'>
          <div class="form-group row mb-4">
              <label class="col-sm-2 col-form-label ">
                <span class="h5 font-weight-bold">{{i18n.cropNm}}</span>
                <span class="text-danger required"></span>
              </label>
              <div class="col-sm-4">
              <label class="col-sm-2 col-form-label ">
                <span class="h5">{{cropNm}}</span>
              </label>
              </div>
              <label class="col-sm-2 col-form-label ">
                <span class="h5 font-weight-bold">{{i18n.nutrientTpye}}</span>
              </label>
              <div class="col-sm-4">  
                <b-form-select v-model="nutrientSolutionNo" :options="nutrientSolutionOptions"></b-form-select>
              </div>
            </div>
            <div class="form-group row mb-4">
              <label class="col-sm-2 col-form-label">
                <span class="h5 font-weight-bold">{{i18n.cultivateCropType}}</span>
              </label>
              <div class="col-sm-4">  
                <label class="col-sm-2 col-form-label">
                  <span class="h5">{{cropType}}</span>
                </label>
                </div>
              <label class="col-sm-2 col-form-label">
                <span class="h5 font-weight-bold">{{i18n.cultivateEnvStandards}}</span>
              </label>
              <div class="col-sm-4">  
                <label class="col-sm-2 col-form-label">
                  <span class="h5">{{baseNm}}</span>
                </label>
                </div>
            </div>
            <div class="form-group row mb-4">
              <label class="col-sm-2 col-form-label ">
                <span class="h5 font-weight-bold">{{i18n.seed1}}</span>
              </label>
              <div class="col-sm-4">  
                <label class="col-sm-2 col-form-label ">
                  <span class="h5">{{seed1}}</span>
                </label>
                </div>
              <label class="col-sm-2 col-form-label ">
                <span class="h5 font-weight-bold">{{i18n.seed2}}</span>
              </label>
              <div class="col-sm-4">  
                <label class="col-sm-2 col-form-label ">
                  <span class="h5">{{seed2}}</span>
                </label>
                </div>
            </div>
          <!-- begin::form-group -->
          <div class="form-group row mb-4">
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.monthAvgOutput}}</span>
            </label>
            <div class="col-sm-4">
              <div class="input-icon input-icon-right">
                <input v-model="monthAvgOutput" type="number" class="form-control">
                <span>kg</span>
              </div>
            </div>

            <label class="col-sm-2 col-form-label pl-6">
              <span class="h5 font-weight-bold">{{i18n.connect}}</span>
            </label>
            <div class="col-sm-4">
              <div class="input-icon input-icon-right">
                <b-form-select v-model="cropConnectSeq" :options="connectOptions"></b-form-select>
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.growthPeriod}}</span>
            </label>
            <div class="col-sm-4">
              <div class="input-icon input-icon-right">
                <input v-model="growPeriod" type="number" class="form-control">
                <span>{{i18n.day}}</span>
              </div>
            </div>

            <label class="col-sm-2 col-form-label pl-6">
              <span class="h5 font-weight-bold">{{i18n.standardWeight}}</span>
            </label>
            <div class="col-sm-4">
              <div class="input-icon input-icon-right">
                <input v-model="standardWeight" type="number" class="form-control">
                <span>g</span>
              </div>
            </div>
          </div>

          <!-- begin::form-group -->
            <div class="form-group mb-4">
              <label class="col-form-label">
                <span class="h5 font-weight-bold">{{i18n.informationByCropSeason}}</span>
              </label>
              <div class="form-section-body p-5 border rounded">
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <span class="font-weight-bold">{{i18n.seedling}}</span>
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>{{i18n.dayCount}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedMinDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedMedianDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedMaxDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>EC</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.seedEcMin' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.seedEcMedian' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.seedEcMax' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>pH</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedPhMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedPhMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.seedPhMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-section-body p-5 border rounded">
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <span class="font-weight-bold">{{i18n.transplant}}</span>
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>{{i18n.dayCount}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantMinDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantMedianDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">

                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantMaxDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>EC</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantEcMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantEcMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantEcMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                </div>
                
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>pH</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantPhMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantPhMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.transplantPhMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-section-body p-5 border rounded" style = "margin-top:15px;">  




                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <span class="font-weight-bold">{{i18n.lastfeedDay}}</span>
                  </label>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>1. {{i18n.dayCount}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedMinDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedMedianDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">

                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedMaxDay" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">{{i18n.day}}</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2. {{i18n.temp}}</small>
                  </label>

                    </div>


                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small> 2 - 1 {{i18n.tempDay}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayTemperatureMin' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayTemperatureMedian' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayTemperatureMax' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                </div>

                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>2 - 2 {{i18n.tempNight}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedNightTemperatureMin' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedNightTemperatureMedian' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedNightTemperatureMax' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>3. {{i18n.waterTemp}}</small>
                  </label>

                    </div>

                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>3 - 1. {{i18n.waterTempDay}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedDayWaterTemperatureMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedDayWaterTemperatureMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedDayWaterTemperatureMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>3 - 2. {{i18n.waterTempNight}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightWaterTemperatureMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightWaterTemperatureMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightWaterTemperatureMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">°C</span>
                    </div>
                  </div>
                </div>
                
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>4. {{i18n.humidity}}</small>
                  </label> 
                  </div>


                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>4 - 1. {{i18n.humidityDay}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayHumidityMin' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayHumidityMedian' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model='jsonData.lastfeedDayHumidityMax' type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                </div>

                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>4 - 2. {{i18n.humidityNight}}</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightHumidityMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightHumidityMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedNightHumidityMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">%</span>
                    </div>
                  </div>
                </div>

                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>5. CO2</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedCo2Min" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ppm</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedCo2Median" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ppm</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedCo2Max" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ppm</span>
                    </div>
                  </div>
                </div>


                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>6. EC</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedEcMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedEcMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedEcMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">ds/m</span>
                    </div>
                  </div>
                </div>
                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>7. pH</small>
                  </label>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Min</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedPhMin" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">{{i18n.titration}}</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedPhMedian" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex align-items-center">
                      <small class="opacity-40 p-0">Max</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedPhMax" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                      <span class="pl-3">pH</span>
                    </div>
                  </div>
                </div>

                <!-- begin::form-group -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    <small>8. {{i18n.light}}</small>
                  </label>
                </div>
           

                
                <!-- begin::form-group 광량 -->
                <div class="form-group row field-wide">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>8 - 1. {{i18n.lightIntensity}}</small>
                  </label>
                  <!-- begin::field -->
                  <div class="row col-10 ">
                    <div class="col-3"></div>
                    <div class="col-6 align-items-center">
                      <div class="col-9 col-md-10 px-0">
                        <div class="input-icon input-icon-right">
                          <input v-model="jsonData.lastfeedLight" type="number" class="form-control" placeholder="적정값">
                          <span style="width: calc(5em + 1.3rem + 3px)">μmol/m²s</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end::field -->
                </div>
                <!-- end::form-group 광량 -->
                <!-- begin::form-group 광질 -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>8 - 2. {{i18n.lightColor}}</small>
                  </label>
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">
                      <small>R({{i18n.red}})</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedLightR" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">:</div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">
                      <small>G({{i18n.green}})</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedLightG" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">:</div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">

                      <small>B({{i18n.bule}})</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedLightB" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                   <div class="col-1 d-flex align-items-center justify-content-center">:</div>  
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">                    
                      <small>W({{i18n.white}})</small>
                      <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected col-8">
                        <input v-model="jsonData.lastfeedLightW" type="number" class="form-control bootstrap-touchspin-vertical-btn">
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::form-group 광질 -->
                <!-- begin::form-group 일조량 -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label" style = "padding-left:30px;">
                    <small>8 - 3. {{i18n.lightTime}}</small>
                  </label>
                  <!-- begin::field -->
                  <div class="row col-8 ">
                    <div class="col d-flex align-items-center">
                      <small class="col-3 col-md-2 px-0 opacity-60">Start Time</small>
                      <div class="col-9 col-md-10 px-5">
                        <div class="input-icon input-icon-right">
                          <input v-model="jsonData.lastfeedLightIntensityMin" type="number" class="form-control" placeholder="Start Time">
                        </div>
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">~</div>
                    <div class="col d-flex align-items-center">
                      <small class="col-3 col-md-2 px-0 opacity-60">End Time</small>
                      <div class="col-9 col-md-10 px-0">
                        <div class="input-icon input-icon-right">
                          <input v-model="jsonData.lastfeedLightIntensityMax" type="number" class="form-control" placeholder="End Time">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end::field -->
                </div>
                <!-- end::form-group 일조량 -->
          </div>
        </div>
        <!-- begin::card-footer -->
        <div class="card-footer">
          <div class="d-flex align-items-center float-right">
              <button type="button" class="btn btn-lg btn-dark mr-1" @click="updateCrop()">{{i18n.edit}}</button>
              <button type="button" class="btn btn-lg btn-secondary" @click="cropModal = false">{{i18n.cancel}}</button>
          </div>
        </div>
        </div>
        </b-modal>
</template>

<script>
import {ACT_UPDATE_MY_CROP,ACT_GET_MY_CONNECTS,ACT_GET_NUTRIENT_SOLUTIONS} from '@/core/services/variable';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import {getResult, isSuccess, lan} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  props: {
    farmCropModal: Number,
    selectedCrop: Object,
    getCrops: Function,
  },
  beforeMount(){
    this.$store.dispatch(ACT_GET_MY_CONNECTS).then(resp =>{
      resp.items[0].farmConnects.forEach(connect =>{
        if(connect.internetConnectYn == 'Y'){
          this.connectOptions.push({value: connect.seq, text: connect.connectNm})
        }
      })
    })
    this.$store.dispatch(ACT_GET_NUTRIENT_SOLUTIONS).then(resp=>{
      resp.items.forEach(item =>{
        this.nutrientSolutionOptions.push({value: item.nutrientSolutionNo, text: item.nutrientSolutionNm })
      })
    })
  },
  data(){
    return{
      i18n: {
        edit: i18n.t('farm.cultivationCropMangement.edit'),
        cancel: i18n.t('farm.cultivationCropMangement.cancel'),
        cropNm: i18n.t('farm.cultivationCropMangement.cropNm'),
        nutrientTpye: i18n.t('farm.cultivationCropMangement.nutrientTpye'),
        cultivateCropType: i18n.t('farm.cultivationCropMangement.cultivateCropType'),
        cultivateEnvStandards: i18n.t('farm.cultivationCropMangement.cultivateEnvStandards'),
        day: i18n.t('farm.cultivationCropMangement.day'),
        titration: i18n.t('farm.cultivationCropMangement.titration'),
        red: i18n.t('farm.cultivationCropMangement.red'),
        bule: i18n.t('farm.cultivationCropMangement.bule'),
        green: i18n.t('farm.cultivationCropMangement.green'),
        white: i18n.t('farm.cultivationCropMangement.white'),
        seed1: i18n.t('farm.cultivationCropMangement.seed1'),
        seed2: i18n.t('farm.cultivationCropMangement.seed2'),
        connect: i18n.t('farm.cultivationCropMangement.connect'),
        informationByCropSeason: i18n.t('farm.cultivationCropMangement.informationByCropSeason'),
        seedling: i18n.t('farm.cultivationCropMangement.seedling'),
        transplant: i18n.t('farm.cultivationCropMangement.transplant'),
        lastfeedDay: i18n.t('farm.cultivationCropMangement.lastfeedDay'),
        dayCount: i18n.t('farm.cultivationCropMangement.dayCount'),
        temp: i18n.t('farm.cultivationCropMangement.temp'),
        tempDay: i18n.t('farm.cultivationCropMangement.tempDay'),
        tempNight: i18n.t('farm.cultivationCropMangement.tempNight'),
        waterTemp: i18n.t('farm.cultivationCropMangement.waterTemp'),
        waterTempDay: i18n.t('farm.cultivationCropMangement.waterTempDay'),
        waterTempNight: i18n.t('farm.cultivationCropMangement.waterTempNight'),
        humidity: i18n.t('farm.cultivationCropMangement.humidity'),
        humidityDay: i18n.t('farm.cultivationCropMangement.humidityDay'),
        humidityNight: i18n.t('farm.cultivationCropMangement.humidityNight'),
        light: i18n.t('farm.cultivationCropMangement.light'),
        lightIntensity: i18n.t('farm.cultivationCropMangement.lightIntensity'),
        lightColor: i18n.t('farm.cultivationCropMangement.lightColor'),
        lightTime: i18n.t('farm.cultivationCropMangement.lightTime'),
        monthAvgOutput: i18n.t('farm.cultivationCropMangement.monthAvgOutput'),
        standardWeight: i18n.t('farm.cultivationCropMangement.standardWeight'),
        growthPeriod: i18n.t('farm.cultivationCropMangement.growthPeriod'),
        error: i18n.t('farm.cultivationCropMangement.error'),
        cropD1: i18n.t('farm.cultivationCropMangement.cropD1'),
        cropD4: i18n.t('farm.cultivationCropMangement.cropD4'),
       },

      cropModal: false,

      cropNm: '',
      nutrientSolutionNo: 0,
      nutrientSolutionNm: '',
      cropType: '',
      baseNm: '',
      seed1: '',
      seed2: '',
      growPeriod: 0,
      standardWeight: 0,
      monthAvgOutput: 0,
      cropConnectNm: '',
      cropConnectSeq: 0,
      connectOptions: [],
      nutrientSolutionOptions: [],
  
      jsonData:{
        seedMinDay: 0,
        seedMedianDay: 0,
        seedMaxDay: 0,
        seedEcMin:0,
        seedEcMedian:0,
        seedEcMax:0,
        seedPhMin:0,
        seedPhMedian:0,
        seedPhMax:0,

        transplantMinDay: 0,
        transplantMedianDay: 0,
        transplantMaxDay: 0,
        transplantEcMin:0,
        transplantEcMedian:0,
        transplantEcMax:0,
        transplantPhMin:0,
        transplantPhMedian:0,
        transplantPhMax:0,

        lastfeedlantMinDay: 0,
        lastfeedMinDay: 0,
        lastfeedMedianDay: 0,
        lastfeedMaxDay: 0,
        lastfeedDayTemperatureMin:0,
        lastfeedDayTemperatureMedian:0,
        lastfeedDayTemperatureMax:0,
        lastfeedNightTemperatureMin:0,
        lastfeedNightTemperatureMedian:0,
        lastfeedNightTemperatureMax:0,

        lastfeedDayWaterTemperatureMin:0,
        lastfeedDayWaterTemperatureMedian:0,
        lastfeedDayWaterTemperatureMax:0,
        lastfeedNightWaterTemperatureMin:0,
        lastfeedNightWaterTemperatureMedian:0,
        lastfeedNightWaterTemperatureMax:0,

        lastfeedDayHumidityMin:0,
        lastfeedDayHumidityMedian:0,
        lastfeedDayHumidityMax:0,
        lastfeedNightHumidityMin:0,
        lastfeedNightHumidityMedian:0,
        lastfeedNightHumidityMax:0,

        lastfeedLight:0,
        lastfeedLightR:0,
        lastfeedLightG:0,
        lastfeedLightB:0,
        lastfeedLightW:0,
        lastfeedLightIntensityMin:0,
        lastfeedLightIntensityMax:0,

        lastfeedEcMin:0,
        lastfeedEcMedian:0,
        lastfeedEcMax:0,
        lastfeedPhMin:0,
        lastfeedPhMedian:0,
        lastfeedPhMax:0,

        lastfeedCo2Min:0,
        lastfeedCo2Median:0,
        lastfeedCo2Max:0,
      },
    }
  },
  watch:{
    farmCropModal(){
      this.cropModal = true
      this.cropNm = lan() == 'ko' ? this.selectedCrop.cropNm : this.selectedCrop.cropNmEn
      this.nutrientSolutionNo = this.selectedCrop.nutrientSolutionNo
      this.nutrientSolutionNm = this.selectedCrop.nutrientSolutionNm
      this.cropType = lan() == 'ko' ? this.selectedCrop.cropType :  this.selectedCrop.cropTypeEn
      this.baseNm = this.selectedCrop.baseNm
      this.seed1 = this.selectedCrop.seed1
      this.seed2 = this.selectedCrop.seed2
      this.growPeriod = this.selectedCrop.growPeriod
      this.standardWeight = this.selectedCrop.standardWeight
      this.monthAvgOutput = this.selectedCrop.monthAvgOutput
      this.cropConnectSeq = this.selectedCrop.cropConnectSeq
      this.cropConnectNm = this.selectedCrop.cropConnectNm
      Object.keys(this.jsonData).forEach(key =>{
        this.jsonData[key] = this.selectedCrop[key]
      })
    }
  },
  methods:{
    updateCrop(){
      const cropList = {}
      Object.keys(this.jsonData).forEach(key =>{
        cropList[key] = this.jsonData[key]
      })
      cropList.standardWeight = this.standardWeight
      cropList.growPeriod = this.growPeriod
      const cropJsonList = JSON.stringify(cropList)
      const params ={
        cropConnectSeq: this.cropConnectSeq,
        fno: this.$store.state.farm.farmItem.fno,
        monthAvgOutput: this.monthAvgOutput,
        nutrientSolutionNo: this.nutrientSolutionNo,
        cropEnvJson: cropJsonList
      }
      this.$store.dispatch(ACT_UPDATE_MY_CROP,{cultivateCropSeq: this.selectedCrop.cultivateCropSeq, params: params}).then(resp => {
        if(isSuccess(resp)){
          this.$store.dispatch(ACT_ADD_ALERT, {message: `${this.i18n.cropD1}`+`${this.cropNm}`+`${this.i18n.cropD4}`, color: 'success'});
          this.getCrops()
          this.cropModal = false
        }else{
          this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
        }
      }).catch(e => {
        console.error(e);
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
      });
      
    }
  }
}
</script>

<style>

</style>
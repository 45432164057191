<template>
  <div v-if="totalCount" class="pagination mt-12">
    <nav aria-hidden="false" aria-label="Pagination">
      <ul aria-disabled="false" class="pagination b-pagination">
        <li aria-hidden="true" class="page-item farm-page-link" v-bind:class="{'disabled': (pageNo === 1)}" :disabled="(pageNo === 1)" @click="move(1, pageNo === 1)"><span aria-label="Go to first page" aria-disabled="true" class="page-link"><i class="fas fa-angle-double-left"></i></span></li>
        <li aria-hidden="true" class="page-item farm-page-link" v-bind:class="{'disabled': (startPageList - 1) === 0}" :disabled="(startPageList - 1) === 0" @click="move(startPageList - 1, (startPageList - 1) === 0)"><span aria-label="Go to previous page" aria-disabled="true" class="page-link"><i class="fas fa-angle-left"></i></span></li>

        <li v-bind:key="i" v-for="(i) in this.numberRange" class="page-item farm-page-link" v-bind:class="{'active': i === pageNo}"><a href="javascript:;" class="page-link" @click="move(i, pageNo === i)"><span class="font-size-sm font-weight-bold" v-bind:class="{'text-white': i === pageNo, 'text-dark': i !== pageNo}">{{ i }}</span></a></li>

        <li aria-hidden="true" class="page-item farm-page-link" v-bind:class="{'disabled': endPageList >= totalPage}" :disabled="endPageList >= totalPage" @click="move(endPageList + 1, endPageList >= totalPage)"><span aria-label="Go to next page" aria-disabled="true" class="page-link"><i class="fas fa-angle-right"></i></span></li>
        <li aria-hidden="true" class="page-item farm-page-link" v-bind:class="{'disabled' : pageNo >= totalPage}" :disabled="pageNo >= totalPage" @click="move(totalPage, pageNo >= totalPage)"><span aria-label="Go to last page" aria-disabled="true" class="page-link"><i class="fas fa-angle-double-right"></i></span></li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'ModalPagination',
  props: {
    pageNo: Number,
    pageSize: Number,
    totalCount: Number,
    callback: Function,
    effectValue: Number
  },
  created() {
    this.renew();
  },
  watch : {
    effectValue(){
      this.renew();
    }
  },
  data (){
    return {
      pageListSize: 5,
      totalPage: 0,
      totalPageList: 0,
      pageList: 0,
      startPageList: 0,
      endPageList: 0,
      numberRange: []
    }
  },
  methods: {
    renew() {

      this.totalPage = Math.ceil(this.totalCount / this.pageSize)
      this.totalPageList = Math.ceil(this.totalPage / this.pageListSize)
      this.pageList = Math.ceil(this.pageNo / this.pageListSize)

      if (this.pageList < 1) this.pageList = 1;
      if (this.pageList > this.totalPageList) this.pageList = this.totalPageList;

      this.startPageList = (this.pageList - 1) * this.pageListSize + 1
      this.endPageList = this.startPageList + this.pageListSize - 1

      if (this.startPageList < 1) this.startPageList = 1
      if (this.endPageList > this.totalPage) this.endPageList = this.totalPage
      if (this.endPageList < 1) this.endPageList = 1

      const range = []
      for (let i = this.startPageList; i <= this.endPageList; i++) {

        range.push(i);
      }
      this.numberRange = range
    },
    move (i, isDisabled) {
      if(!isDisabled) this.callback(i)
    }
  }
};
</script>